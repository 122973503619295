var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{staticClass:"mb-0",attrs:{"small":"","border":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"header-top text-center font-weight-bold",attrs:{"colspan":"5"}})],1),_c('b-tr',[_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",staticStyle:{"width":"20%"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.career", false, "Programa").toUpperCase())+" ")]),_c('b-th',{staticClass:"secondary-color text-left pl-3 align-middle",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.career ? _vm.career.name : "")+" ")]),_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",staticStyle:{"width":"5%"}},[(
              !(
                (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                _vm.$debug_change_duoc
              )
            )?_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.cycle", true, "Ciclos Formativos" ).toUpperCase())+" "),(_vm.allows_crud)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Editar los ${_vm.$getVisibleNames(
                  'mesh.cycle',
                  true,
                  'Ciclos Formativos'
                )}.`
              ),expression:"\n                `Editar los ${$getVisibleNames(\n                  'mesh.cycle',\n                  true,\n                  'Ciclos Formativos'\n                )}.`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":_vm.launchCycleModal}}):_vm._e()],1):_vm._e()]),_c('b-th',{staticClass:"secondary-color align-middle",staticStyle:{"width":"5%"}},[(
              !(
                (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                _vm.$debug_change_duoc
              )
            )?_c('div',{staticClass:"campus-container"},[_vm._l((_vm.egressProfileCycles),function(cycle){return _c('div',{key:cycle.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(cycle.name)+" ")])}),(_vm.egressProfileCycles.length == 0)?_c('div',{staticClass:"ml-2"},[_vm._v(" No Aplica. ")]):_vm._e()],2):_vm._e()])],1),_c('b-tr',[_c('b-th',{staticClass:"secondary-color text-left font-weight-bold",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$getVisibleNames("mesh.faculty", false, "FACULTAD").toUpperCase()))]),_c('b-th',{staticClass:"secondary-color text-left pl-3",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.faculty ? _vm.faculty.name : "")+" ")]),_c('b-th',{staticClass:"secondary-color text-left font-weight-bold",staticStyle:{"width":"15%"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofiletype", false, "Tipo de Perfil de Egreso" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"secondary-color",staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('div',{staticClass:"ml-2 text-left"},[_vm._v(" "+_vm._s(_vm.egressProfileType ? _vm.egressProfileType : "No Aplica.")+" ")])])],1),_c('b-tr',[_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.mesh.titulo_profesional", false, "Título Profesional" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"secondary-color align-middle",staticStyle:{"width":"35%"},attrs:{"colspan":"1"}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?_c('b-input',{attrs:{"type":"text","placeholder":`Introduzca el ${_vm.$getVisibleNames(
              'manual.mesh.titulo_profesional',
              false,
              'Título Profesional'
            )}`,"size":"sm"},on:{"update":_vm.saveProfile},model:{value:(_vm.egress_profile.professional_title),callback:function ($$v) {_vm.$set(_vm.egress_profile, "professional_title", $$v)},expression:"egress_profile.professional_title"}}):_c('div',{staticClass:"ml-2 text-left"},[_vm._v(" "+_vm._s(_vm.egress_profile.professional_title)+" ")])],1),_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",staticStyle:{"width":"15%"},attrs:{"colspan":"1"}},[_vm._v(" DURACIÓN ")]),_c('b-th',{staticClass:"secondary-color align-middle",staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?_c('div',[_c('div',{staticClass:"d-flex"},[_c('b-input',{attrs:{"type":"number","min":"1","placeholder":`Duración del ${_vm.$getVisibleNames(
                  'mesh.career',
                  false,
                  'Programa'
                ).toLowerCase()}`,"size":"sm"},on:{"update":function($event){return _vm.changeEPMatterAndCourseWrapper()}},model:{value:(_vm.egress_profile.semester_amount),callback:function ($$v) {_vm.$set(_vm.egress_profile, "semester_amount", $$v)},expression:"egress_profile.semester_amount"}})],1)]):_c('div',{staticClass:"ml-2 text-left"},[_vm._v(" "+_vm._s(_vm.egress_profile.semester_amount)+" ")])])],1),(
          !(_vm.institution.internal_use_id == 'duoc_uc' || _vm.$debug_change_duoc)
        )?_c('b-tr',[(_vm.institution.show_academic_degree)?_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.grado_academico", false, "Grado Académico" ).toUpperCase())+" ")]):_vm._e(),(_vm.institution.show_academic_degree)?_c('b-th',{staticClass:"secondary-color align-middle",attrs:{"colspan":"1"}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?_c('b-input',{attrs:{"type":"text","placeholder":`Introduzca el ${_vm.$getVisibleNames(
              'manual.grado_academico',
              false,
              'Grado Académico'
            )}`,"size":"sm"},on:{"update":_vm.saveProfile},model:{value:(_vm.egress_profile.academic_degree),callback:function ($$v) {_vm.$set(_vm.egress_profile, "academic_degree", $$v)},expression:"egress_profile.academic_degree"}}):_c('div',{staticClass:"ml-2 text-left"},[_vm._v(" "+_vm._s(_vm.egress_profile.academic_degree)+" ")])],1):_vm._e(),_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.selectedCampuses.length > 1 ? _vm.$getVisibleNames("mesh.campus", true, "Sedes").toUpperCase() : _vm.$getVisibleNames("mesh.campus", false, "Sede").toUpperCase()))]),_c('b-th',{staticClass:"secondary-color",attrs:{"colspan":_vm.institution.show_academic_degree ? 1 : 3}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?[_c('v-select',{staticClass:"v-select-class",attrs:{"options":_vm.campusListFiltered,"reduce":(campusListFiltered) => campusListFiltered.id,"selectable":(option) => !_vm.selected_campuses.includes(option.id),"multiple":"","placeholder":`Seleccione uno o varias ${_vm.$getVisibleNames(
                'mesh.campus',
                true,
                'Sedes'
              )}`,"label":"name","track-by":"id","size":"sm"},on:{"input":_vm.saveProfile},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}}],null,false,738677531),model:{value:(_vm.selected_campuses),callback:function ($$v) {_vm.selected_campuses=$$v},expression:"selected_campuses"}})]:[_c('div',{staticClass:"campus-container campus-scroll"},_vm._l((_vm.selectedCampuses),function(campus){return _c('div',{key:campus.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(campus.name)+" ")])}),0)]],2)],1):_vm._e(),_c('b-tr',[_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.egress_profile_code", false, "Código de Perfil de Egreso" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"secondary-color align-middle",attrs:{"colspan":"1"}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?[_c('b-input',{attrs:{"type":"text","state":_vm.egress_profile_code_list.filter(
                  (x) =>
                    _vm.$equals(x.code, _vm.egress_profile.code) &&
                    x.id != _vm.egress_profile.id
                ).length == 0,"aria-describedby":"input-code-feedback","placeholder":`Introduzca el ${_vm.$getVisibleNames(
                'manual.egress_profile_code',
                false,
                'Código de Perfil de Egreso'
              )}`,"size":"sm"},on:{"update":(value) => {
                  _vm.egress_profile.code = value.toUpperCase();
                  _vm.saveProfile();
                }},model:{value:(_vm.egress_profile.code),callback:function ($$v) {_vm.$set(_vm.egress_profile, "code", $$v)},expression:"egress_profile.code"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-code-feedback"}},[(
                  this.egress_profile_code_list.find(
                    (x) =>
                      this.$equals(x.code, this.egress_profile.code) &&
                      x.id != this.egress_profile.id
                  )
                )?_c('div',{staticClass:"text-left"},[_vm._v(" El "+_vm._s(_vm.$getVisibleNames( "manual.egress_profile_code", false, "Código de Perfil de Egreso" ))+" ya está siendo utilizado. ")]):_vm._e()])]:_c('div',{staticClass:"ml-3 text-left"},[(
                _vm.egress_profile.code == null ||
                _vm.egress_profile.code.trim() == ''
              )?_c('span',[_vm._v(" N/A ")]):_c('span',[_vm._v(" "+_vm._s(_vm.egress_profile.code)+" ")])])],2),_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.modalityegressprofile", false, "Modalidad del Perfil de Egreso" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"secondary-color align-middle",attrs:{"colspan":"1"}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?[_c('b-form-select',{attrs:{"options":_vm.modalities_egress_profiles,"value-field":"id","text-field":"name"},on:{"change":_vm.saveProfile},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("N/A ")])]},proxy:true}],null,false,3112793182),model:{value:(_vm.egress_profile.modality),callback:function ($$v) {_vm.$set(_vm.egress_profile, "modality", $$v)},expression:"egress_profile.modality"}})]:[_c('div',{staticClass:"text-justify ml-2"},[_vm._v(" "+_vm._s(_vm.modalityProfile)+" ")])]],2)],1),_c('b-tr',[_c('b-th',{staticClass:"secondary-color text-left font-weight-bold align-middle",attrs:{"colspan":"1"}},[(_vm.institution && _vm.institution.show_ep_full_init_date)?[_vm._v(" FECHA DE INICIO ")]:[_vm._v("AÑO DE ENTRADA EN VIGENCIA")]],2),_c('b-th',{staticClass:"secondary-color",attrs:{"colspan":"1"}},[(_vm.institution && _vm.institution.show_ep_full_init_date)?[_c('div',{staticClass:"text-left ml-2"},[_vm._v(" "+_vm._s(_vm._f("FormatToFullDateEsp")(_vm.egress_profile.init_date))+" ")])]:[_c('div',{staticClass:"text-left ml-2"},[_vm._v(" "+_vm._s(_vm.egress_profile.init_date)+" ")]),_c('b-form-invalid-feedback',{attrs:{"id":"input-init_date-live-feedback"}},[_vm._v("Ingrese un año válido")])]],2),_c('b-th',{staticClass:"secondary-color text-left font-weight-bold",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.facultyarea", false, "Área de Facultad" ).toUpperCase()))]),_c('b-th',{staticClass:"secondary-color",attrs:{"colspan":"1"}},[(
              _vm.allows_crud_header &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?[_c('b-form-select',{attrs:{"options":_vm.faculty_areas,"value-field":"id","text-field":"name"},on:{"change":_vm.saveProfile},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("N/A ")])]},proxy:true}],null,false,3112793182),model:{value:(_vm.egress_profile.faculty_area),callback:function ($$v) {_vm.$set(_vm.egress_profile, "faculty_area", $$v)},expression:"egress_profile.faculty_area"}})]:[_c('div',{staticClass:"text-justify ml-2"},[_vm._v(" "+_vm._s(_vm.facultyAreas)+" ")])]],2)],1)],1)],1),_vm._l((_vm.errors),function(error,index){return _c('p',{key:`error-${index}`,staticClass:"error-p"},[_vm._v(" "+_vm._s(error)+" ")])}),_c('DescriptorsContainer',{attrs:{"ref_component":'EgressProfile',"Title_text":_vm.$getVisibleNames('mesh.egressprofile', true, 'Perfiles de Egreso'),"Text_info_tooltip":`Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros ${_vm.$getVisibleNames(
      'mesh.egressprofile',
      true,
      'Perfiles de Egreso'
    )}.`,"Title":_vm.egressProfileDescriptor.filter((x) => x.page_position == 1),"Descriptors":_vm.epMiddleDescriptors.filter((a) =>
        _vm.egressProfileDescriptor
          .filter((b) => b.page_position == 1)
          .map((c) => c.id)
          .includes(a.descriptor)
      ),"allows_crud":_vm.allows_crud &&
      ((_vm.user_position &&
        [1, 2, 3].includes(_vm.user_position.position) &&
        [1, 2, 3].includes(_vm.user.groups[0])) ||
        _vm.user.is_superuser ||
        _vm.$hasObjectPermission('mesh.career', 'change', _vm.egress_profile.career)),"Order":7,"VisibleOrder":false,"ProgramView":true,"PagePosition":1},on:{"updateOrder":_vm.updateOrderEpMiddleDescriptors,"updateState":_vm.updateVisibleEpMiddleDescriptors,"updateDescription":_vm.updateEpMiddleDescriptors,"createDescription":_vm.createEpMiddleDescriptors,"createTitle":_vm.createProfileDescriptors,"updateTitle":_vm.updateProfileDescriptors,"deleteDescriptors":_vm.deleteProfileDescriptors,"deleteTitle":_vm.deleteTitle}}),_c('b-table-simple',{staticClass:"competences-table",attrs:{"small":"","responsive":"","border":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center font-weight-bold",attrs:{"colspan":5 + _vm.competenceDescriptor.length}},[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.competence", true, "Competencias" ).toUpperCase())+" "),(
              _vm.allows_crud &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?_c('div',{staticClass:"float-right"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_egressprofilecompetence'),expression:"'mesh.add_egressprofilecompetence'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Agregar ${_vm.$getVisibleNames(
                  'mesh.competence',
                  true,
                  'Competencias'
                ).toLowerCase()}`
              ),expression:"\n                `Agregar ${$getVisibleNames(\n                  'mesh.competence',\n                  true,\n                  'Competencias'\n                ).toLowerCase()}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"secondary-button",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`new-competence-modal`)}}},[_c('b-icon-plus'),_vm._v("Agregar")],1),_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_egressprofilecompetence'),expression:"'mesh.add_egressprofilecompetence'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Seleccionar ${_vm.$getVisibleNames(
                  'mesh.competence',
                  true,
                  'Competencias'
                ).toLowerCase()}`
              ),expression:"\n                `Seleccionar ${$getVisibleNames(\n                  'mesh.competence',\n                  true,\n                  'Competencias'\n                ).toLowerCase()}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"secondary-button ml-3",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`selected-competence-modal`)}}},[_vm._v("Seleccionar ")]),_c('b-modal',{attrs:{"id":`selected-competence-modal`,"title":`Seleccionar ${_vm.$getVisibleNames(
                'mesh.competence',
                true,
                'Competencias'
              )}`,"size":"lg","hide-footer":""},on:{"hide":_vm.hideModal}},[_c('SelectedCompetence',{attrs:{"egress_profile_id":_vm.egress_profile_id,"cycle_id":_vm.egressCycleId,"display_id":`selected-competence-modal`},on:{"slotUpdate":_vm.slotUpdateProfileCompetence}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.saveProfileCompetence()}}},[_vm._v("Guardar")])],1)])],1),_c('b-modal',{attrs:{"id":`new-competence-modal`,"title":`Redactar ${_vm.$getVisibleNames(
                'mesh.competence',
                false,
                'Competencia'
              )}`,"size":"lg","hide-footer":""}},[_c('CompetenceForm',{attrs:{"egress_profile_id":_vm.egress_profile_id,"cycle_id":_vm.egressCycleId,"order":_vm.suggestedOrder,"egress_profile_view":true,"can_edit_cycle":false,"can_edit_order":true},on:{"deleted":function($event){return _vm.$bvModal.hide(`new-competence-modal`)},"created":function($event){return _vm.$bvModal.hide(`new-competence-modal`)}}})],1)],1):_vm._e()])],1),_c('b-tr',[(_vm.competencesList.filter((x) => x.type != null).length > 0)?_c('b-th',[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.competencetype", false, "Tipo de Competencias" ).toUpperCase())+" ")])]):_vm._e(),_c('b-th',{class:{
            'w-80':
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc,
            'w-100': !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            ),
          },attrs:{"colspan":_vm.allows_crud &&
            ((_vm.user_position &&
              [1, 2, 3].includes(_vm.user_position.position) &&
              [1, 2, 3].includes(_vm.user.groups[0])) ||
              _vm.user.is_superuser ||
              _vm.$hasObjectPermission(
                'mesh.career',
                'change',
                _vm.egress_profile.career
              ))
              ? 2
              : 1}},[_c('strong',[_vm._v("REDACCIÓN")])]),_vm._l((_vm.competenceDescriptor),function(descriptor){return [_c('b-th',{key:descriptor.key},[_c('strong',[_vm._v(_vm._s(descriptor.label.toUpperCase()))])])]}),(
            (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
            _vm.$debug_change_duoc
          )?_c('b-th',{staticClass:"w-20"},[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.capacity", true, "Capacidades" ).toUpperCase()))])]):_vm._e(),(
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?[(
              (_vm.institution && _vm.institution.show_base_ra) || _vm.$debug_change_duoc
            )?_c('b-th',[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.basera", false, "RA Base" ).toUpperCase())+" ")])]):_vm._e()]:_vm._e(),(
            _vm.allows_crud &&
            ((_vm.user_position &&
              [1, 2, 3].includes(_vm.user_position.position) &&
              [1, 2, 3].includes(_vm.user.groups[0])) ||
              _vm.user.is_superuser ||
              _vm.$hasObjectPermission(
                'mesh.career',
                'change',
                _vm.egress_profile.career
              ))
          )?_c('b-th'):_vm._e()],2)],1),_c('draggable',{attrs:{"disabled":!(
          _vm.allows_crud &&
          ((_vm.user_position &&
            [1, 2, 3].includes(_vm.user_position.position) &&
            [1, 2, 3].includes(_vm.user.groups[0])) ||
            _vm.user.is_superuser ||
            _vm.$hasObjectPermission(
              'mesh.career',
              'change',
              _vm.egress_profile.career
            ))
        ),"tag":"b-tbody","handle":".handle"},model:{value:(_vm.competencesList),callback:function ($$v) {_vm.competencesList=$$v},expression:"competencesList"}},[(_vm.competencesList.length > 0)?_vm._l((_vm.competencesList),function(competence){return _c('b-tr',{key:competence.id},[(
              _vm.competencesList.filter((x) => x.type != null).length > 0 &&
              (_vm.firsCompetenceType(competence) ||
                (_vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2, 3].includes(_vm.user_position.position) &&
                    [1, 2, 3].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      _vm.egress_profile.career
                    ))))
            )?_c('b-td',{staticClass:"row-secondary-color",attrs:{"rowspan":_vm.allows_crud &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
                ? 1
                : _vm.competencesList.filter((x) => x.type == competence.type)
                    .length}},[_c('strong',[_vm._v(" "+_vm._s(_vm.competenceTypes.find((x) => x.id == competence.type) ? _vm.competenceTypes .find((x) => x.id == competence.type) .name.toUpperCase() : "")+" ")])]):_vm._e(),(
              _vm.allows_crud &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?_c('b-td',{staticClass:"text-center handle",class:{
              grabbable:
                _vm.allows_crud &&
                ((_vm.user_position &&
                  [1, 2, 3].includes(_vm.user_position.position) &&
                  [1, 2, 3].includes(_vm.user.groups[0])) ||
                  _vm.user.is_superuser ||
                  _vm.$hasObjectPermission(
                    'mesh.career',
                    'change',
                    _vm.egress_profile.career
                  )),
            }},[(
                _vm.allows_crud &&
                ((_vm.user_position &&
                  [1, 2, 3].includes(_vm.user_position.position) &&
                  [1, 2, 3].includes(_vm.user.groups[0])) ||
                  _vm.user.is_superuser ||
                  _vm.$hasObjectPermission(
                    'mesh.career',
                    'change',
                    _vm.egress_profile.career
                  ))
              )?_c('b-icon',{attrs:{"icon":"arrows-expand","scale":"1.2"}}):_vm._e()],1):_vm._e(),_c('b-td',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex ml-3"},[_c('span',[_c('strong',[_vm._v(_vm._s(competence.order)+".")])]),_c('span',{class:_vm.orderState(competence) ? 'text-danger' : ''},[_c('SentenceContainer',{attrs:{"Sentence":competence,"order_hint":_vm.orderState(competence) ? true : false}})],1)])]),_vm._l((_vm.competenceDescriptor),function(description){return _c('b-td',{key:description.key},[_vm._v(" "+_vm._s(competence.descriptions.find( (x) => String("descriptor_" + x.descriptor__id) == description.key ) ? competence.descriptions.find( (x) => String("descriptor_" + x.descriptor__id) == description.key ).description : "")+" ")])}),(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )?_c('b-td',[_vm._v(" "+_vm._s(_vm.getCapacities(competence.capacities))+" ")]):_vm._e(),(
              !(
                (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                _vm.$debug_change_duoc
              )
            )?[(
                (_vm.institution && _vm.institution.show_base_ra) ||
                _vm.$debug_change_duoc
              )?_c('b-td',[_c('div',{staticClass:"ml-2"},[_c('h5',{key:`ra-base-header-${competence.id}`,class:{
                    'pointer-class':
                      _vm.allows_crud &&
                      ((_vm.user_position &&
                        [1, 2, 3].includes(_vm.user_position.position) &&
                        [1, 2, 3].includes(_vm.user.groups[0])) ||
                        _vm.user.is_superuser ||
                        _vm.$hasObjectPermission(
                          'mesh.career',
                          'change',
                          _vm.egress_profile.career
                        )),
                  },attrs:{"id":`ra-base-header-${competence.id}`},on:{"click":() => {
                      if (
                        _vm.allows_crud &&
                        ((_vm.user_position &&
                          [1, 2, 3].includes(_vm.user_position.position) &&
                          [1, 2, 3].includes(_vm.user.groups[0])) ||
                          _vm.user.is_superuser ||
                          _vm.$hasObjectPermission(
                            'mesh.career',
                            'change',
                            _vm.egress_profile.career
                          ))
                      )
                        _vm.$bvModal.show(`modal-base-ra-${competence.id}`);
                    }}},[_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.raBasesFiltered(competence.competence).length))])],1),_c('b-modal',{attrs:{"id":`modal-base-ra-${competence.id}`,"hide-footer":"","title":_vm.$getVisibleNames('teaching.basera', false, 'RA Base'),"size":"lg"}},[_c('RaBase',{attrs:{"competence_id":competence.competence}})],1),_c('b-popover',{key:`ra-base-tooltip-${competence.id}`,attrs:{"target":`ra-base-header-${competence.id}`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base")))]},proxy:true}],null,true)},[(_vm.raBasesFiltered(competence.competence).length > 0)?[_c('div',{staticClass:"ra-competences-container"},_vm._l((_vm.raBasesFiltered(competence.competence)),function(ra){return _c('span',{key:ra.id},[_c('SentenceContainer',{attrs:{"Sentence":ra}})],1)}),0)]:[_vm._v("No cuenta con ningún "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" creado ")]],2)],1)]):_vm._e()]:_vm._e(),(
              _vm.allows_crud &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.career',
                  'change',
                  _vm.egress_profile.career
                ))
            )?_c('b-td',[_c('div',{staticClass:"noprint button-competence"},[(
                  _vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2, 3].includes(_vm.user_position.position) &&
                    [1, 2, 3].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      _vm.egress_profile.career
                    ))
                )?_c('div',{staticClass:"button-wrap"},[_c('div',{staticClass:"edit-button"},[_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_egressprofilecompetence'),expression:"'mesh.change_egressprofilecompetence'"}],on:{"click":function($event){return _vm.$bvModal.show(`modal-edit-competence-${competence.id}`)}}})],1),_c('div',{staticClass:"edit-button"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_egressprofilecompetence'),expression:"'mesh.delete_egressprofilecompetence'"}],staticClass:"p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.askForDeleteCompetence(competence.id)}}},[_c('b-icon',{attrs:{"icon":"trash-fill","scale":"0.9"}})],1)],1),_c('b-modal',{attrs:{"id":`modal-edit-competence-${competence.id}`,"title":`Modificar ${_vm.$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencias'
                  )}`,"size":"lg","hide-footer":""}},[_c('CompetenceForm',{attrs:{"Competence":_vm.competences.find((x) => x.id == competence.competence),"egress_profile_id":_vm.egress_profile_id,"type_id":competence.type,"cycle_id":_vm.egressCycleId,"egress_profile_view":true,"can_edit_cycle":false,"can_edit_order":true,"can_edit_type":true,"allows_crud":true,"show_cycle":false},on:{"updated":function($event){return _vm.$bvModal.hide(`modal-edit-competence-${competence.id}`)},"reset_competence":function($event){return _vm.changeCompetenceDescriptor(competence)},"reset_competence_table":_vm.resetCompetenceTable,"change_middle_descriptors":() => {
                        _vm.changeCompetenceDescriptor(competence);
                      }}})],1)],1):_vm._e()])]):_vm._e()],2)}):_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":_vm.allows_crud &&
            ((_vm.user_position &&
              [1, 2, 3].includes(_vm.user_position.position) &&
              [1, 2, 3].includes(_vm.user.groups[0])) ||
              _vm.user.is_superuser ||
              _vm.$hasObjectPermission(
                'mesh.career',
                'change',
                _vm.egress_profile.career
              ))
              ? 6 + _vm.competenceDescriptor.length
              : 5 + _vm.competenceDescriptor.length}},[_vm._v(" No hay "+_vm._s(_vm.$getVisibleNames("mesh.competence", true, "Competencias"))+" para mostrar. ")])],1)],2)],1),(
      (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
      _vm.$debug_change_duoc
    )?[(_vm.egress_profile_id)?[(
          _vm.profile_competences_mention.filter(
            (x) => x.egress_profile == _vm.egress_profile_id
          ).length > 0 || _vm.allows_crud
        )?_c('EgressProfileCompetenceMention',{attrs:{"allows_crud":_vm.allows_crud,"egress_profile_id":_vm.egress_profile_id}}):_vm._e()]:_vm._e()]:_vm._e(),_c('DescriptorsContainer',{attrs:{"ref_component":'EgressProfileDescriptor2',"Title_text":_vm.$getVisibleNames('mesh.egressprofile', true, 'Perfiles de Egreso'),"Text_info_tooltip":`Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros ${_vm.$getVisibleNames(
      'mesh.egressprofile',
      true,
      'Perfiles de Egreso'
    )}.`,"Title":_vm.egressProfileDescriptor.filter((x) => x.page_position == 2),"Descriptors":_vm.epMiddleDescriptors.filter((a) =>
        _vm.egressProfileDescriptor
          .filter((b) => b.page_position == 2)
          .map((c) => c.id)
          .includes(a.descriptor)
      ),"allows_crud":_vm.allows_crud &&
      ((_vm.user_position &&
        [1, 2, 3].includes(_vm.user_position.position) &&
        [1, 2, 3].includes(_vm.user.groups[0])) ||
        _vm.user.is_superuser ||
        _vm.$hasObjectPermission('mesh.career', 'change', _vm.egress_profile.career)),"Order":_vm.lengthEgressProfileDescriptor + 1,"VisibleOrder":false,"ProgramView":true,"PagePosition":2},on:{"updateOrder":_vm.updateOrderEpMiddleDescriptors,"updateState":_vm.updateVisibleEpMiddleDescriptors,"updateDescription":_vm.updateEpMiddleDescriptors,"createDescription":_vm.createEpMiddleDescriptors,"createTitle":_vm.createProfileDescriptors,"updateTitle":_vm.updateProfileDescriptors,"deleteDescriptors":_vm.deleteProfileDescriptors,"deleteTitle":_vm.deleteTitle}}),_c('b-modal',{attrs:{"id":"modal-change-ep-matter-coruse-wrapper","title":`Duración del ${_vm.$getVisibleNames(
      'mesh.egressprofile',
      false,
      'Perfil de Egreso'
    )}: ${_vm.egress_profile.semester_amount}`,"size":"xl","hide-footer":""},on:{"hide":_vm.returnSemesterAmount}},[(
        _vm.courses_wrapper.filter(
          (x) =>
            x.init_level > _vm.egress_profile.semester_amount &&
            x.egress_profile == _vm.egress_profile_id
        ).length > 0
      )?[_c('CoursesWrapperView',{attrs:{"semester_amount":_vm.egress_profile.semester_amount == '' ||
          _vm.egress_profile.semester_amount == null
            ? 0
            : _vm.egress_profile.semester_amount,"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud,"matrix2_view":false},on:{"list_courses_wrapper":_vm.updateCoursesWrapperView}})]:_vm._e(),(
        _vm.egressProfileMatters.filter(
          (x) =>
            x.plan_level > _vm.egress_profile.semester_amount &&
            x.egress_profile == _vm.egress_profile_id
        ).length > 0
      )?[(_vm.user && _vm.user.is_superuser)?_c('RemoveEPMattersComponent',{attrs:{"semester_amount":_vm.egress_profile.semester_amount == '' ||
          _vm.egress_profile.semester_amount == null
            ? 0
            : _vm.egress_profile.semester_amount,"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud,"matrix2_view":false},on:{"list_matters":_vm.updateRemoveEPMattersComponent}}):_vm._e()]:_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"margin-left":"0","margin-right":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.noninteractive",value:(
            `Al cancelar, la duración del ${_vm.$getVisibleNames(
              'mesh.egressprofile',
              false,
              'Perfil de Egreso'
            )} volverá a su estado original.`
          ),expression:"\n            `Al cancelar, la duración del ${$getVisibleNames(\n              'mesh.egressprofile',\n              false,\n              'Perfil de Egreso'\n            )} volverá a su estado original.`\n          ",modifiers:{"v-danger":true,"noninteractive":true}}],staticClass:"ml-3",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.hide(`modal-change-ep-matter-coruse-wrapper`)}}},[_vm._v(" Cancelar ")])],1),_c('div',{staticStyle:{"margin-left":"auto","margin-right":"0"}},[(
            _vm.list_courses_wrapper.filter(
              (x) =>
                (x.init_level > _vm.egress_profile.semester_amount ||
                  x.end_level < x.init_level ||
                  x.end_level > _vm.egress_profile.semester_amount ||
                  x.init_level <= 0 ||
                  x.end_semester <= 0 ||
                  x.init_level == '' ||
                  x.end_level == '') &&
                x.egress_profile == _vm.egress_profile_id
            ).length == 0 &&
            _vm.list_matters.filter(
              (x) =>
                (x.plan_level > _vm.egress_profile.semester_amount ||
                  x.plan_level == '' ||
                  x.plan_level <= 0) &&
                x.egress_profile == _vm.egress_profile_id
            ).length == 0
          )?[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.removeMattersAndCoursesWrapper()}}},[_vm._v("Aplicar cambios")])]:[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","disabled":""}},[_vm._v("Aplicar cambios")])]],2)])],2),_c('b-modal',{attrs:{"id":"egressprofile-file-modal","title":`Anexos`,"size":"xl","hide-footer":""}},[_c('EgressProfileFileView',{attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud},on:{"closeModal":function($event){return _vm.$bvModal.hide('egressprofile-file-modal')}}})],1),(
      _vm.allows_crud &&
      ((_vm.user_position &&
        [1, 2, 3].includes(_vm.user_position.position) &&
        [1, 2, 3].includes(_vm.user.groups[0])) ||
        _vm.user.is_superuser ||
        _vm.$hasObjectPermission('mesh.career', 'change', _vm.egress_profile.career))
    )?[_c('CycleModal',{attrs:{"selected_ids":_vm.egress_profile.cycles,"egress_profile":_vm.egress_profile},on:{"ok":_vm.slotUpdateCycles}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }